import type { IconClass } from '#core/types'

function isPlainObject(value) {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false
  }

  const prototype = Object.getPrototypeOf(value)
  return prototype === null || prototype === Object.prototype
}

export type TransformMap<T> = Partial<Record<keyof ControlOption<T>, string>>

export type RawControlOptions<T> = ControlOption[] | Record<string, T> | string[] | string

export interface ControlOption<T = any> {
  value: T
  label: string
  icon?: IconClass
  checked?: boolean
  disabled?: boolean
  [key: string]: any
}

/**
 * Helper function to easily generate options for Switches, Dropdowns, Tabs,etc
 *
 * Accepts:
 *
 * - array of objects, i.e. [{ value, label, ... }, ... ]
 * - object of label:value pairs, i.e. { 'Label': value, ... }
 * - array of string labels, i.e. ['One', 'Two', ... ]
 * - string of pipe-separated labels, i.e. 'One|Two|...'
 *
 * Note that:
 *
 * - string, string[], and object[] without `value` key  inputs return `index` (i.e. number) for `value`
 * - pass 2nd argument true to transform string and string[] values to a key of the label
 */
export function toControlOptions(input: string | string[]): ControlOption<number>[] // strings to index
export function toControlOptions(input: string | string[], transform?: true): ControlOption<string>[] // strings to key
export function toControlOptions<T = any>(input: Record<string, T>): ControlOption<T>[] // object
export function toControlOptions<T = any>(input: Record<string, T>[], transform: TransformMap<T>): ControlOption<T>[] // objects
export function toControlOptions<T = any>(input: RawControlOptions<T>, transform?: TransformMap<T> | boolean): ControlOption<T>[] {
  // array of string labels, i.e. ['One', 'Two', ... ]
  if (Array.isArray(input) && input.every((option: unknown) => typeof option === 'string')) {
    return input.map((label, index) => {
      return {
        value: transform
          ? toKey(label)
          : index,
        label,
      }
    })
  }

  // array of objects, i.e. [{ value, label, ... }, ... ]
  if (Array.isArray(input)) {
    const map = {
      value: 'value',
      label: 'label',
      ...transform,
    }
    return input.map((option, index) => {
      return {
        ...option,
        value: option[map.value] ?? index,
        label: option[map.label] ?? `Item ${index}`,
      }
    })
  }

  // object of label:value pairs, i.e. { 'Label': value, ... }
  if (isPlainObject(input)) {
    return Object.entries(input).map(([label, value]) => {
      return { value, label }
    })
  }

  // string of pipe-separated labels, i.e. 'One|Two|...'
  if (typeof input === 'string') {
    return toControlOptions(input.trim().split('|'), transform)
  }

  // default return
  return input
}

function toKey(label: string): string {
  return label
    .toLocaleLowerCase()
    .replaceAll(/ +/g, '_')
    .replaceAll(/\W+/g, '')
}
